import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { RootContext, rootStore } from '../store/rootStore'
import { createRoot } from 'react-dom/client'
import { CompatRouter } from 'react-router-dom-v5-compat'
import '../utils/initErrorLogger'
import { ErrorBoundary } from '../ErrorBoundary'

const container = document.getElementById('root')

const root = createRoot(container)

rootStore.authStore.getMe()

const App = React.lazy(() => import('../App'))

root.render(
    <ErrorBoundary>
        <BrowserRouter>
            <CompatRouter>
                <RootContext.Provider value={rootStore}>
                    <App />
                </RootContext.Provider>
            </CompatRouter>
        </BrowserRouter>
    </ErrorBoundary>
)
